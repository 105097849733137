import React, { useEffect, useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoVector from '../../assets/svgs/LogoVector';
import TextGradientComponent from '../../components/TextGradientComponent';
import { initializeWebSocket } from '../../webSocket';

const Login = () => {
    const [businessPin, setBusinessPin] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // New state for loading
    const [uniqueIdentifier, setUniqueIdentifier] = useState(''); // New state for loading
    const navigate = useNavigate();

    const handleChange = (newValue) => {
        setBusinessPin(newValue);
        // Reset the error and loading when onChange is triggered
        setError('');
        setLoading(false);
    };

    useEffect(() => {
        const uniqueIdentifier = generateRandomString(6);
        setUniqueIdentifier(uniqueIdentifier)
    }, [])


    const handleComplete = async (value) => {
        if (value.length === 5) {
            setLoading(true); // Start loading when the condition is met

            try {
                if (await initializeWebSocket(uniqueIdentifier, value)) {
                    setLoading(false);
                    navigate(`/dashboard/${uniqueIdentifier}`);
                } else {
                    setLoading(false);
                    setError('Incorrect PIN. Please try again.');
                }
            } catch (error) {
                setLoading(false);
                setError('Server error. Please try again later.');
            }
        };
    };

    const customCharValidator = (character, index) => {
        return /^[a-zA-Z0-9]+$/.test(character);
    };

    // Function to generate a random alphanumeric string
    const generateRandomString = (length) => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset.charAt(randomIndex);
        }
        return result;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <LogoVector maxWidth="100px" maxHeight="100px" />
            <Typography variant="h4" color="text.secondary" textAlign="center" mb="2rem">
                <TextGradientComponent text="NtelCare &trade; Notification Center" size="1em" />
            </Typography>

            <Card
                sx={{
                    width: '80%',
                    maxWidth: '400px',
                    padding: '1rem',
                    backgroundColor: (theme) => theme.palette.customColor,
                }}
            >
                <Typography variant="h4" textAlign="center">
                    Enter 5 Character Business Code
                </Typography>
                <CardContent>
                    <MuiOtpInput
                        value={businessPin}
                        onChange={handleChange}
                        onComplete={handleComplete}
                        validateChar={customCharValidator}
                        length={5}
                        autoFocus
                        sx={{
                            gap: 1,
                        }}
                        TextFieldsProps={{ placeholder: '-' }}
                    />
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {loading && <CircularProgress size={24} color="success" />}
                    {error && (
                        <Typography variant="h5" color="text.secondary" sx={{ color: '#ef5350' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </Card>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2rem'
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    v1.0.0.15
                </Typography>
            </Box>
        </Box>
    );
};

export default Login;
