import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Drawer,
  List,
  ListItem,
} from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import SeniorCard from "../../components/SeniorCard";
import { ColorModeContext } from "../../theme";
import LogoVector from "../../assets/svgs/LogoVector";
import { useNavigate, useParams } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';
import AudioTest from "../../components/AudioTest";
import Footer from "../../components/Footer";
import DashboardHeader from "../../components/DashboardHeader";
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import ResetIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import useAlertSound from "../../hooks/useAlertSound";

const Dashboard = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { id } = useParams();
  const [isFullScreen, setFullScreen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchQuery, setSearchQuery] = useState("");

  const [openSoundCheck, setOpenSoundCheck] = useState(true);
  const [soundClick, setSoundClick] = useState(false);

  const [mergedDeviceData, setMergedDeviceData] = useState([]);
  const [filteredMergedDeviceData, setFilteredMergedDeviceData] = useState([]);

  const [shouldPlaySound, setShouldPlaySound] = useState(false);

  // Function to merge device data by asnId
  const mergeDeviceDataByAsnId = (data) => {
    const mergedData = data.reduce((acc, item) => {
      // Use asnId as the key for merging
      const key = item.asnId;
      if (!acc[key]) {
        acc[key] = { ...item }; // If this is the first time we've seen this asnId, add the item as is
      } else {
        // Merge current item's properties into the existing entry, customizing merging logic as needed
        for (const [property, value] of Object.entries(item)) {
          // Example merging logic: if the property exists, append or concatenate the value
          if (acc[key][property] && Array.isArray(acc[key][property])) {
            acc[key][property] = [...new Set([...acc[key][property], value])]; // For arrays, merge unique values
          } else if (acc[key][property] && typeof acc[key][property] === 'object') {
            acc[key][property] = { ...acc[key][property], ...value }; // For objects, merge properties
          } else {
            acc[key][property] = value; // For other types, replace the value
          }
        }
      }
      return acc;
    }, {});

    return Object.values(mergedData); // Convert the merged data object back into an array
  };

  const navigate = useNavigate();

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => console.error('An error happened 💥'),
  });

  useEffect(() => {
    const handleLogoutOnUnload = () => {
      handleLogout();
    };

    window.addEventListener('beforeunload', handleLogoutOnUnload);

    return () => {
      window.removeEventListener('beforeunload', handleLogoutOnUnload);
    };
  }, []);

  useEffect(() => {
    const storedDeviceData = JSON.parse(localStorage.getItem(`deviceData${id}`));
    if (storedDeviceData === null) {
      handleLogout()
    } else {
      setDeviceData(storedDeviceData || []);
    }
  }, [deviceData, id]);

  useEffect(() => {
    request()
  }, []);

  // Effect to merge device data whenever it changes
  useEffect(() => {
    if (deviceData.length > 0) {
      const mergedData = mergeDeviceDataByAsnId(deviceData);
      setMergedDeviceData(mergedData);
      setFilteredMergedDeviceData(mergedData); // Initially, set filtered data to all merged data
    }
  }, [deviceData]);

  useEffect(() => {
    const anyAlertActive = filteredMergedDeviceData.some((data) =>
      (data.fallDetected === "true" && data.fallConfirmed === "true") ||
      data.bedExitAlarm === "true"
    );
    setShouldPlaySound(anyAlertActive); // Update the sound state based on whether any alert is active
  }, [filteredMergedDeviceData]);

  // Hook to control the alarm sound
  useAlertSound(shouldPlaySound);

  const handleSearch = (query) => {
    setSearchQuery(query);
    // Use functional update to avoid direct state mutation
    setFilteredMergedDeviceData((currentData) => {
      return mergedDeviceData.filter((data) =>
        data.roomId?.toString().toLowerCase().includes(query.toLowerCase()) ||
        data.seniorName?.toLowerCase().includes(query.toLowerCase())
      );
    });
  };

  const handleFullScreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  };

  const handleNotificationClick = () => {
  };

  const handleLogout = async () => {
    localStorage.removeItem(`deviceData${id}`);
    localStorage.clear();
    await navigate('/', { replace: true });
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const drawerItems = [
    {
      text: "Notifications",
      icon: <NotificationsOutlinedIcon />,
      onClick: handleNotificationClick,
    },
    {
      text: "Fullscreen",
      icon: isFullScreen ? (
        <FullscreenExitOutlinedIcon />
      ) : (
        <FullscreenOutlinedIcon />
      ),
      onClick: handleFullScreenToggle,
    },
    {
      text: "Dark Mode",
      icon: theme.palette.mode === "dark" ? (
        <DarkModeOutlinedIcon />
      ) : (
        <LightModeOutlinedIcon />
      ),
      onClick: colorMode.toggleColorMode,
    },
    {
      text: "Monitoring",
      icon: <SensorsIcon />,
      onClick: () => {
        handleLogout();
      },
    },
    {
      text: "Inactive",
      icon: <SensorsOffIcon />,
      onClick: () => {
        handleLogout();
      },
    },
    {
      text: "Offline",
      icon: <SignalWifiStatusbarConnectedNoInternet4Icon />,
      onClick: () => {
        handleLogout();
      },
    },
    {
      text: "Reset",
      icon: <ResetIcon />,
      onClick: () => {
        handleLogout();
      },
    },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        handleLogout();
      },
    },
  ];

  return (
    <>
      {deviceData &&
        <Box sx={{ paddingTop: '60px' }}>
          <DashboardHeader
            deviceData={deviceData}
            handleSearch={handleSearch}
            isSmallScreen={isSmallScreen}
            toggleDrawer={toggleDrawer}
            handleNotificationClick={handleNotificationClick}
            handleFullScreenToggle={handleFullScreenToggle}
            isFullScreen={isFullScreen}
            colorMode={colorMode}
            handleLogout={handleLogout}
          />
          <Drawer anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
            <List>
              <ListItem>
                <IconButton onClick={() => toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </ListItem>
              {drawerItems.map((item, index) => (
                <ListItem key={index} onClick={item.onClick}>
                  <IconButton>
                    {item.icon}
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Box m="12px">
            <Grid container spacing={2}>
              {filteredMergedDeviceData.map((data, index) => (
                <Grid key={index} item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <SeniorCard data={data} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2rem'
            }}
          >
            <LogoVector maxWidth="28px" maxHeight="28px" />
            <Typography variant="h6" color="text.secondary">
              NtelCare &trade; Notification Center
            </Typography>
          </Box>
          <Footer />
          {openSoundCheck && (
            <AudioTest
              openSoundCheck={openSoundCheck}
              setOpenSoundCheck={setOpenSoundCheck}
              setSoundClick={setSoundClick}
              soundClick={soundClick} />
          )}
        </Box>
      }
    </>
  );
};

export default Dashboard;
