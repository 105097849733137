import React, { useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Grid,
} from '@mui/material';
import useSound from 'use-sound';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const AudioTest = ({ openSoundCheck, setOpenSoundCheck, soundClick, setSoundClick }) => {
    const AlertSound = 'https://ncdashboardresources.s3.amazonaws.com/alert.mp3'
    const [play, { stop }] = useSound(AlertSound, { volume: 1 });

    const handleTest = () => {
        play();
        setSoundClick(true);
    };

    const handleDone = () => {
        stop();
        setOpenSoundCheck(false);
    };

    useEffect(() => {
        if (!openSoundCheck) {
            stop();
        }
    }, [openSoundCheck, stop]);

    return (
        <Box>
            <Dialog
                open={openSoundCheck}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h2" color="textSecondary">
                        We need to test your system sound before proceeding..
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Grid sx={{ display: 'flex'}}>
                        <Grid item xs={6} mr={1}>
                            <Typography variant="h5" color="textPrimary">
                                Please click "Test" to test your system sound.
                            </Typography>
                            <Typography variant="h5" color="textPrimary">
                                Click "Done" if you hear the alert sound.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <VolumeUpIcon sx={{ fontSize: 50 }}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTest}
                        color="warning"
                        variant="contained"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        Test
                    </Button>
                    <Button
                        onClick={handleDone}
                        color="success"
                        variant="contained"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                            display: soundClick ? 'block' : 'none',
                        }}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AudioTest;