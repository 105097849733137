import { useEffect, useRef } from 'react';
import useSound from 'use-sound';

const useAlertSound = (shouldPlay) => {
  const AlertSound = 'https://ncdashboardresources.s3.amazonaws.com/alert.mp3';
  const [play, { stop }] = useSound(AlertSound, { volume: 1 });
  const intervalIdRef = useRef(null);

  useEffect(() => {
    if (shouldPlay) {
      // Play the sound every 2 seconds
      intervalIdRef.current = setInterval(() => {
        play();
      }, 2000);
    } else {
      // Stop the sound if it's playing
      stop();
      clearInterval(intervalIdRef.current);
    }

    // Cleanup on unmount or when `shouldPlay` changes
    return () => {
      stop();
      clearInterval(intervalIdRef.current);
    };
  }, [shouldPlay, play, stop]);
};

export default useAlertSound;
