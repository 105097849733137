import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import Login from "./screens/login";
import React from "react";

function App() {
	const [theme, colorMode] = useMode();

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
					<main className="content">
						<Routes>
							<Route exact path="/" element={<Login />} />
							<Route exact path="/dashboard/:id" element={<Dashboard />} />
						</Routes>
					</main>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
