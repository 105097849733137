import React, { useState, useEffect } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

// SearchBar component
const SearchBar = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const [timerId, setTimerId] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    // Check if the input is empty, and if so, revert to viewing all data
    if (value.trim() === "") {
      onSearch(""); // Pass an empty string to the parent component
    } else {
      onSearch(value);

      // Reset the timer for clearing the search bar
      if (timerId) {
        clearTimeout(timerId);
      }

      // Set a new timer to clear the search bar after 10 seconds of inactivity
      setTimerId(
        setTimeout(() => {
          setSearchValue("");
          onSearch(""); // Clear the search bar and revert to viewing all data
        }, 10000)
      );
    }
  };

  const handleSearch = () => {
    onSearch(searchValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    // Clear the timer when the component unmounts or when searchValue changes
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId, searchValue]);

  return (
    <Box>
      <TextField
        placeholder="Search..."
        variant="outlined"
        size="small"
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        sx={{ width: '80%', ml: "2rem" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
