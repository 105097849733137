import React, { useState } from 'react';
import { Box, IconButton, Typography, Badge, Button, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchBar from "../../src/components/SearchBar";
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import ResetIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { useTheme } from '@mui/material/styles';

const DashboardHeader = ({
    deviceData,
    handleSearch,
    isSmallScreen,
    toggleDrawer,
    handleNotificationClick,
    handleFullScreenToggle,
    isFullScreen,
    colorMode,
    handleLogout
}) => {
    const theme = useTheme();
    const reverseColor = theme.palette.mode === 'dark' ? 'white' : 'black';
    const hoverBackgroundColor = theme.palette.mode === 'dark' ? 'black' : 'white';

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState([]);

    const getOfflineDevicesCount = (deviceData) => {
        const currentTime = Date.now(); // Get the current timestamp in milliseconds
        const offlineThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
    
        // Filter devices that are offline
        const offlineDevices = deviceData.filter(device => {
            // Handle cases where the timestamp is null or invalid
            if (!device.timestamp || device.timestamp === null) {
                return true; // Consider devices with a null or invalid timestamp as offline
            }
    
            const deviceTimestamp = parseInt(device.timestamp, 10);
    
            // Check if the device timestamp is older than the offline threshold
            return currentTime - deviceTimestamp > offlineThreshold;
        });
    
        return offlineDevices.length; // Return the number of offline devices
    };
    

    const getOfflineDevices = (deviceData) => {
        const currentTime = Date.now(); // Get the current timestamp in milliseconds
        const offlineThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
    
        return deviceData.filter(device => {
            // Handle cases where the timestamp is null or missing
            if (!device.timestamp || device.timestamp === null) {
                return true; // Consider devices with a null or missing timestamp as offline
            }
    
            const deviceTimestamp = parseInt(device.timestamp, 10);
    
            // Check if the device timestamp is older than the offline threshold
            return currentTime - deviceTimestamp > offlineThreshold;
        });
    };
    
    // Calculate the number of offline devices
    const offlineDevicesCount = getOfflineDevicesCount(deviceData);

    // Handle the click event to show the popover
    const handleOfflineClick = (event) => {
        const offlineDevices = getOfflineDevices(deviceData);
        setPopoverData(offlineDevices);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'offline-popover' : undefined;

    // Function to detect the device type (Fall Detection or Bed Exit)
    const getDeviceType = (device) => {
        if (device.vFallDeviceId) {
            return "Fall Detection";
        } else if (device.vBedExitDeviceId) {
            return "Bed Exit";
        } else {
            return "Unknown Device";
        }
    };

    return (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1100, width: '100%', bgcolor: 'background.paper' }}>
            <Box display="flex" justifyContent="space-between" p={1} alignItems="center">
                <Box ml="5px">
                    <Typography variant="h4" color="text.secondary" noWrap>
                        {deviceData[0] ? deviceData[0].businessName : null}
                    </Typography>
                    <Typography variant="body1" color="#78B69B">
                        powered by ntelcare
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    {!isSmallScreen && (
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <Button
                            variant="text"
                            sx={{
                                '&:hover': {
                                    bgcolor: hoverBackgroundColor,
                                    color: reverseColor,
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <SensorsIcon color="action" />
                                <Typography variant="body2" sx={{ color: reverseColor }}>{deviceData.length - offlineDevicesCount}  Monitoring</Typography>
                            </Box>
                        </Button>

                        <Button
                            variant="text"
                            sx={{
                                '&:hover': {
                                    bgcolor: hoverBackgroundColor,
                                    color: reverseColor,
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <SensorsOffIcon color="action" />
                                <Typography variant="body2" sx={{ color: reverseColor }}>0 Inactive</Typography>
                            </Box>
                        </Button>

                        <Button
                            variant="text"
                            sx={{
                                '&:hover': {
                                    bgcolor: hoverBackgroundColor,
                                    color: reverseColor,
                                },
                            }}
                            onClick={handleOfflineClick}
                        >
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <SignalWifiStatusbarConnectedNoInternet4Icon color="action" />
                                <Typography variant="body2" sx={{ color: reverseColor }}>{offlineDevicesCount} Offline</Typography>
                            </Box>
                        </Button>

                        <Popover
                            id={popoverId}
                            open={isPopoverOpen}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={2}>
                                {popoverData.length > 0 ? (
                                    popoverData.map((device, index) => (
                                        <Typography key={index} variant="body2">
                                            Room: {device.roomId} - Device: {getDeviceType(device)}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant="body2">No offline devices</Typography>
                                )}
                            </Box>
                        </Popover>

                        <Button
                            variant="text"
                            sx={{
                                '&:hover': {
                                    bgcolor: hoverBackgroundColor,
                                    color: reverseColor,
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <ResetIcon color="action" />
                                <Typography variant="body2" sx={{ color: reverseColor }}>Reset</Typography>
                            </Box>
                        </Button>
                    </Box>
                    )}
                    <Box>
                        <SearchBar onSearch={handleSearch} />
                    </Box>
                    {isSmallScreen && (
                        <IconButton onClick={() => toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    {!isSmallScreen && (
                        <>
                            <IconButton onClick={handleNotificationClick}>
                                <NotificationsOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={handleFullScreenToggle}>
                                {isFullScreen ? (
                                    <FullscreenExitOutlinedIcon />
                                ) : (
                                    <FullscreenOutlinedIcon />
                                )}
                            </IconButton>
                            <IconButton onClick={colorMode.toggleColorMode}>
                                {colorMode === "dark" ? (
                                    <DarkModeOutlinedIcon />
                                ) : (
                                    <LightModeOutlinedIcon />
                                )}
                            </IconButton>
                            <IconButton onClick={handleLogout}>
                                <LogoutIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardHeader;
