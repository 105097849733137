import React from 'react';
import { Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import FallConfirmed2 from '../assets/svgs/Fall-confirmed2';
import FallDetected2 from '../assets/svgs/Fall-detected2';
import BedExit from '../assets/svgs/BetExit';

const IconRenderer = ({ bedExitAlarm, presence, fallDetectedBool, fallConfirmedBool, theme }) => {
  if (bedExitAlarm === true) {
    return (
      <BedExit
        width="62"
        height="62"
        color="#FAFAFF"
        strokeWidth={0.1}
        strokeColor="black"
      />
    );
  }

  switch (presence) {
    case "unassigned":
      return (
        <PersonOffIcon
          style={{ fontSize: "50px", color: theme.palette.primary.main, marginBottom: "0.8rem" }}
        />
      );
    case "vacant":
      return (
        <PersonIcon
          style={{ fontSize: "50px", marginBottom: "0.8rem" }}
        />
      );
    case "occupied":
      if (fallDetectedBool) {
        if (fallConfirmedBool) {
          return (
            <Box>
              <FallConfirmed2
                width="55"
                height="55"
                color="#ef5350"
                strokeWidth={0.1}
                strokeColor="black"
              />
            </Box>
          );
        } else {
          return (
            <Box>
              <FallDetected2
                width="55"
                height="55"
                color="#ff9800"
                strokeWidth={0.1}
                strokeColor="black"
              />
            </Box>
          );
        }
      } else {
        return (
          <PersonIcon
            style={{ fontSize: "50px", color: theme.palette.info.main, marginBottom: "0.8rem" }}
          />
        );
      }
    default:
      return null;
  }
};

export default IconRenderer;
