import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const LinearProgressWithLabel = ({ value }) => {
    // Determine the color based on the value
    const progressBarColor = value > 50 ? 'error' : 'warning';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={value} color={progressBarColor} />
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;
