import React from 'react';

const FallConfirmed2 = ({ width, height, color, strokeWidth, strokeColor }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 55.287 55.287"
      xmlSpace="preserve"
      transform="scale(-1, 1)"
    >
      <g>
        <g fill={color} stroke={strokeColor} strokeWidth={strokeWidth}>
          <circle cx="28.281" cy="12.779" r="6.113" />
          <path
            d="M39.666,25.436c-2.412,1.133-4.777,1.871-7.688,2.626c-1.331-2.398-2.871-4.668-4.542-6.704 c-0.271-0.494-0.647-0.925-1.098-1.284c-0.056-0.062-0.11-0.128-0.167-0.19c-0.511-0.562-1.219-0.828-1.923-0.808 C24.12,19.045,23.992,19.02,23.862,19c-0.37-0.13-0.77-0.186-1.185-0.125c-3.378,0.491-6.902,1.473-10.268,2.849 c-2.339-1.706-4.213-3.223-5.997-5.106c-0.95-1.003-2.532-1.047-3.534-0.097c-1.004,0.95-1.047,2.532-0.099,3.536 c2.272,2.4,4.685,4.287,7.608,6.38c0.318,0.228,0.671,0.365,1.031,0.426c0.187,0.065,0.38,0.112,0.578,0.132 c0.416,0.043,0.85-0.02,1.261-0.199c1.15-0.509,2.344-0.966,3.552-1.37c-1.562,3.161-3.188,6.294-4.842,9.407 c-0.103,0.191-0.177,0.394-0.239,0.596c-2.571,1.759-4.681,4.066-6.931,6.247c-0.953,0.921-1.252,2.049-0.772,3.305 c1.259,3.3,2.588,6.646,4.94,9.338c2.354,2.694,6.668-0.81,4.305-3.513c-1.606-1.837-2.603-4.055-3.488-6.311 c1.328-1.371,2.675-2.772,4.19-3.912c0.227,0.143,0.461,0.267,0.703,0.358c0.063,0.022,0.125,0.04,0.188,0.062 c0.325,2.986,1.179,5.834,1.943,8.773c0.475,1.82,2.108,2.717,3.893,2.062c3.191-1.163,6.381-2.33,9.571-3.496 c3.364-1.229,1.043-6.28-2.306-5.057c-2.191,0.801-4.382,1.601-6.573,2.399c-0.574-2.001-1.096-4.036-1.14-6.077 c-0.003-0.16-0.029-0.31-0.055-0.459c0.215-0.285,0.413-0.593,0.586-0.918c1.809-3.404,3.583-6.832,5.277-10.297 c0.854,1.307,1.647,2.667,2.341,4.047c0.398,0.794,1.161,1.283,1.982,1.366c0.385,0.039,0.777-0.019,1.154-0.164 c0.275,0.024,0.561,0.008,0.844-0.063c3.485-0.881,6.424-1.756,9.412-3.16c1.25-0.588,1.787-2.076,1.197-3.327 C42.405,25.385,40.916,24.849,39.666,25.436z"
          />
          <path
            d="M8.965,6.07c3.522,0.43,3.375,7.211,3.372,7.28c-0.006,0.255,0.161,0.482,0.407,0.55 c0.249,0.07,0.506-0.039,0.632-0.259c0.314-0.552,0.747-0.88,1.323-1.004c1.98-0.429,4.833,1.624,4.862,1.644 c0.099,0.072,0.213,0.107,0.327,0.107c0.172,0,0.341-0.08,0.449-0.23c0.181-0.248,0.126-0.595-0.122-0.776 c-0.133-0.097-3.286-2.364-5.75-1.833c-0.392,0.084-0.745,0.232-1.058,0.441c-0.131-1.814-0.636-4.911-2.529-6.318 c1.98-0.556,5.025-1.78,5.943-4.184c1.184,0.828,3.688,2.366,5.716,2.134c0.819-0.094,1.483-0.459,1.975-1.085 c0.189-0.243,0.147-0.592-0.094-0.78c-0.242-0.19-0.591-0.147-0.78,0.094c-0.305,0.389-0.707,0.606-1.226,0.667 c-2.237,0.262-5.536-2.368-5.569-2.396c-0.156-0.124-0.364-0.156-0.549-0.084c-0.186,0.073-0.317,0.238-0.348,0.435 C15.424,3.938,9.013,4.96,8.948,4.971c-0.274,0.042-0.476,0.28-0.471,0.558C8.481,5.805,8.69,6.037,8.965,6.07z"
          />
          <path
            d="M50.925,14.336c0.01-0.004,0.021-0.01,0.029-0.015c0.273-0.137,0.387-0.472,0.249-0.746 s-0.472-0.385-0.745-0.248l-16.066,8.044L50.925,14.336z"
          />
          <path
            d="M53.174,20.045c-0.078-0.297-0.381-0.476-0.678-0.398l-12.632,3.281l12.864-2.194 c0.015-0.004,0.031-0.007,0.048-0.01C53.073,20.646,53.25,20.342,53.174,20.045z"
          />
          <path
            d="M40.841,11.64c0.18-0.251,0.121-0.6-0.131-0.778c-0.25-0.179-0.601-0.121-0.777,0.129l-3.672,5.15 l4.517-4.427C40.8,11.692,40.822,11.666,40.841,11.64z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default FallConfirmed2;
