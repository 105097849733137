import React from 'react';
import { Box, Typography } from "@mui/material";
import LogoVector from "../../src/assets/svgs/LogoVector";

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper', // Opaque white background
        position: 'fixed', // Keeps the footer at the bottom
        bottom: 0, // Aligns the footer at the bottom of the view
        width: '100%', // Ensures the footer stretches across the screen
        zIndex: 1000, // Ensures the footer stays above other content
      }}
    >
      <LogoVector maxWidth="28px" maxHeight="28px" />
      <Typography variant="h6" color="text.secondary">
        NtelCare &trade; Notification Center
      </Typography>
    </Box>
  );
};

export default Footer;
