import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, useTheme, Button } from "@mui/material";
import { keyframes } from "@emotion/react";
import HotelIcon from '@mui/icons-material/Hotel';
import BathtubIcon from '@mui/icons-material/Bathtub';
import LinearProgressWithLabel from "./LinearProgress";
import { tokens } from "../theme";
import fallImg from '../assets/pngs/fall.png';
import bedExitImg from '../assets/pngs/bedexit.png';
import IconRenderer from "./IconRenderer";

const blinkRedShadow = keyframes`
  0% {
    box-shadow: 0 4px 8px rgba(255,152,0, 0.3); // Minimum opacity set to 0.3
  }
  50% {
    box-shadow: 0 4px 8px rgba(255,152,0, 0.7); // Maximum opacity during the blink
  }
  100% {
    box-shadow: 0 4px 8px rgba(255,152,0, 0.3); // Back to the minimum opacity
  }
`;

const SeniorCard = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    deviceId,
    roomId,
    seniorName,
    timestamp,
    fallDetected,
    fallConfirmed,
    presenceDetected,
    seniorId,
    roomType,
    bedExitAlarm,
    vFallDeviceId,
    vBedExitDeviceId
  } = data;

  const [progress, setProgress] = useState(0);
  const [presence, setPresence] = useState("unassigned");
  const [fallDetectedBool, setFallDetectedBool] = useState(false);
  const [fallConfirmedBool, setFallConfirmedBool] = useState(false);

  // Calculate if the card should have the blinking effect
  // const shouldBlink = (fallDetected === "true" && fallConfirmed === "true") || bedExitAlarm === "true";

  const [showButtons, setShowButtons] = useState(false);
  const [isBedExitAlarm, setIsBedExitAlarm] = useState(bedExitAlarm === 'true');


  useEffect(() => {
    const dismissBedExitAlarm = async () => {
      if (fallDetectedBool && fallConfirmedBool && isBedExitAlarm) {
        // Define the API endpoint
        const apiEndpoint = "https://12nc798mcg.execute-api.us-east-1.amazonaws.com/prod/bed-exit-dismiss";
  
        // Prepare your API payload here
        const payload = {
          "deviceTime": timestamp, 
          "deviceId": vBedExitDeviceId
        };
  
        // Use the Fetch API to make the API call
        try {
          const response = await fetch(apiEndpoint, {
            method: 'POST', // Use the appropriate HTTP method for your API
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': 'dk64IGDN7c8rJYwIxsYH16EPxKWzVmyJ9Cu6Mdzk' // Set your API key here
            },
            body: JSON.stringify(payload) // Convert the payload object to a JSON string
          });
  
          // Check if the response was successful
          if (!response.ok) {
            // If the server response was not ok, throw an error
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          // Extract the JSON data from the response
          const data = await response.json();
          console.log('Success:', data);
          // Update state after successful API call
          setIsBedExitAlarm(false); // Set the bed exit alarm to false after resolving it
        } catch (error) {
          // Handle any errors that occurred during the fetch
          console.error('Error during fetch:', error);
        }
      }
    };
  
    dismissBedExitAlarm();
  }, [fallDetected, fallConfirmed, fallDetectedBool, fallConfirmedBool, isBedExitAlarm, timestamp, vBedExitDeviceId]);

  useEffect(() => {
    if (seniorId) {
      // If there is a seniorId
      if (presenceDetected === "true") {
        // If presence is detected
        setPresence("occupied");
      } else {
        // If presence is not detected
        setPresence("vacant");
      }
      if (fallDetected === "true") {
        setFallDetectedBool(true);
      } else {
        setProgress(0)
        setFallDetectedBool(false);
      }
      if (fallConfirmed === "true") {
        setFallConfirmedBool(true);
        setProgress(0)
      } else {
        setFallConfirmedBool(false);
      }
    }

    let timer;

    // Start the timer if fallDetectedBool is true
    if (fallDetectedBool) {
      timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 54 ? 1 : prevProgress + 1));
      }, 1000);
    }

    return () => {
      // Stop the timer on component cleanup
      clearInterval(timer);
    };
  }, [fallDetectedBool, fallDetected, fallConfirmed, presenceDetected, seniorId]);

  useEffect(() => {
    let timer;
    if ((fallDetectedBool === true && fallConfirmedBool === true) || isBedExitAlarm === true) {
      // Set a timer for 2 minutes (120,000 milliseconds)
      timer = setTimeout(() => {
        setShowButtons(true);
      }, 120000);
    } else {
      // If conditions are not met, ensure buttons are not shown
      setShowButtons(false);
    }

    // Clear the timer if the component unmounts or the variables change
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [fallDetectedBool, fallConfirmedBool, isBedExitAlarm]);

  useEffect(() => {
    // When the prop updates to 'true', update the local state to true as well
    if (data.bedExitAlarm === 'true' && !fallConfirmedBool) {
      setIsBedExitAlarm(true);
    }
  }, [data]);

  useEffect(() => {
    // When the prop updates to 'true', update the local state to true as well
    if (data.bedExitAlarm === 'false') {
      setIsBedExitAlarm(false);
    }
  }, [data]);

  const handleClick = async () => {
    setIsBedExitAlarm(false);
    // Define the API endpoint
    const apiEndpoint = "https://12nc798mcg.execute-api.us-east-1.amazonaws.com/prod/bed-exit-dismiss";

    // Prepare your API payload here
    const payload = {
      "deviceTime": timestamp, "deviceId": vBedExitDeviceId
    };

    // Use the Fetch API to make the API call
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST', // Use the appropriate HTTP method for your API
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'dk64IGDN7c8rJYwIxsYH16EPxKWzVmyJ9Cu6Mdzk' // Set your API key here
        },
        body: JSON.stringify(payload) // Convert the payload object to a JSON string
      });

      // Check if the response was successful
      if (!response.ok) {
        // If the server response was not ok, throw an error
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Extract the JSON data from the response
      const data = await response.json();

      console.log('Success:', data);
      // Handle the successful response here (e.g., update the state)
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error during fetch:', error);
    }
  };


  return (
    <Card
      sx={{
        backgroundColor: colors && colors.primary ? colors.primary[400] : "#2196f3",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // transition: "transform 0.2s, box-shadow 0.2s",
        // "&:hover": {
        //   transform: "scale(1.05)",
        //   boxShadow: "4px 8px 16px rgba(0, 0, 0, 0.2)",
        // },
        // ...(shouldBlink && {
        //   animation: `${blinkRedShadow} 1s infinite`,
        //   boxShadow: 'none',
        // }),
      }}
    >
      {/* Header */}
      <Box
        sx={{
          backgroundColor: colors && colors.primary ? colors.primary[500] : "#2196f3",
          display: "flex",
          flexDirection: "row", // Updated to column layout for small screens
          justifyContent: "space-between",
          borderBottom: `1px solid ${colors && colors.grey ? colors.grey[300] : "#ccc"}`,
          padding: "0.5rem",
        }}
      >
        <Box>
          <Typography variant="h5" color="text.secondary" noWrap style={{ fontSize: 24 }}>
            {seniorName}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative", // Ensures the positioning context for absolutely positioned children
          }}
        >
          {vFallDeviceId && (
            <img
              src={fallImg}
              alt="Fall"
              style={{
                position: 'absolute', // Absolutely position the fall indicator
                right: vBedExitDeviceId ? 14 : 0, // If vBedExitDeviceId is present, move to the right for overlap, else start at the very right
                zIndex: 1, // Higher z-index to ensure this element is above the bed exit indicator when both are present
                width: '24px', // Set the width
                // Optionally, set a height here while maintaining aspect ratio, or let it auto-adjust
              }}
            />
          )}
          {vBedExitDeviceId && (
            <img
              src={bedExitImg}
              alt="Bed Exit"
              style={{
                position: 'absolute', // Absolutely position the bed exit indicator
                right: 0, // Start at the very right of the parent container
                zIndex: 2, // Even higher z-index to place this element on top when both indicators are present
                width: '24px', // Set the width
                height: '24px', // Set the height
                // Optionally, set a height here while maintaining aspect ratio, or let it auto-adjust
              }}
            />
          )}
        </Box>
      </Box>

      {/* Body */}
      <CardContent
        sx={{
          padding: "0.5rem",
          backgroundColor: (!showButtons && isBedExitAlarm === true) ? '#CC9600' : 'default',
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" color="text.secondary" style={{ fontSize: 24 }}>
            Room: {roomId}
          </Typography>
          {roomType === "bedroom" && <HotelIcon sx={{ fontSize: 24 }} />}
          {roomType === "bathroom" && <BathtubIcon sx={{ fontSize: 24 }} />}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "70%",
              padding: "0.5rem",
              height: "10px",
            }}
          >
            {fallDetectedBool && !fallConfirmedBool && !isBedExitAlarm ? <LinearProgressWithLabel value={progress * 1.89} /> : null}
          </Box>
          <IconRenderer
            bedExitAlarm={isBedExitAlarm}
            presence={presence}
            fallDetectedBool={fallDetectedBool}
            fallConfirmedBool={fallConfirmedBool}
            theme={theme}
          />
        </Box>
      </CardContent>

      {/* Footer */}
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          backgroundColor: isBedExitAlarm && !showButtons ? '#FFBE0B' : (colors && colors.primary ? colors.primary[500] : "#2196f3"),
          borderTop: `1px solid ${colors && colors.grey ? colors.grey[500] : "#ccc"}`,
          color: '#FAFAFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {(showButtons && isBedExitAlarm) || (showButtons && fallDetectedBool === true && fallConfirmedBool === true) ? (
          // Render buttons side by side
          <>
            <Button
              onClick={handleClick}
              sx={{
                width: '50%',
                backgroundColor: '#CC9600',
                color: '#FAFAFF',
                height: '100%',
                borderRadius: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                '&:hover': {
                  backgroundColor: '#b28600', // Slightly darker on hover
                },
              }}
            >
              Resolve
            </Button>
            <Button
              onClick={handleClick}
              sx={{
                width: '50%',
                backgroundColor: 'transparent',
                color: '#FAFAFF', // Assuming the text color should be white
                height: '100%',
                borderRadius: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly lighter on hover
                },
              }}
            >
              Incorrect Detection
            </Button>
          </>
        ) : (
          // Otherwise, render the appropriate status
          <Typography
            variant="body1"
            color="text.secondary" // This is your default text color for other states
            sx={{
              fontSize: '20px',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              color: isBedExitAlarm === true ? '#272F3F' : undefined // Set specific color when isBedExitAlarm is true
            }}
          >
            {isBedExitAlarm === true
              ? "IMMINENT BED EXIT"
              : (fallDetectedBool === true && fallConfirmedBool === true)
                ? "Fall detected"
                : presence === "unassigned"
                  ? "The room is not allocated"
                  : presence === "vacant"
                    ? "Monitoring"
                    : "Occupied"}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default SeniorCard;
