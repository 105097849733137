import React from 'react';

const FallDetected2 = ({ width, height, color, strokeWidth, strokeColor }) => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 66 66"
      width={width}
      height={height}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color} stroke={strokeColor} strokeWidth={strokeWidth}>
        <path d="m38.4 49.1v3c0 .5-.4 1-1 1s-1-.5-1-1v-3c0-1.3 2-1.3 2 0z" />
        <path d="m44.4 47.1c.6 0 1 .4 1 1v4c0 .5-.4 1-1 1s-1-.5-1-1v-4c0-.6.5-1 1-1z" />
        <path d="m52.4 22.8c-.2-.5 0-1.1.5-1.3s1.1 0 1.3.5c1 2.2 1.6 4.6 1.8 7.1.1.6-.4 1-.9 1.1h-.1c-.5 0-.9-.4-1-.9-.1-2.3-.7-4.5-1.6-6.5z" />
        <path
          d="m14.1 28.1.3-.7c1.3-2.9 5.9-13.8 7.3-17 .8-1.8 2.9-2.6 4.6-1.8 1.8.8 2.6 2.9 1.8 4.6l-4.9 11.3c1.7 1.3 5.8 4.5 9.4 7.4l8.3-17c1-2 3.4-2.8 5.4-1.8s2.8 3.3 1.8 5.4l-8.9 18.1 17.9-1.9c2.2-.2 4.2 1.4 4.4 3.6s-1.3 4.1-3.6 4.4c-25.6 2.5-24.9 3-26.6 2.3-2-.5-2.1-1.2-11-7.8.5 2.6 1.7 6 3.5 9.3.9 1.7.3 3.8-1.3 4.8-1.7.9-3.8.3-4.8-1.4-6.6-12.3-3.6-21.7-3.6-21.8z"
        />
        <path d="m65 56.7c0 .6-.5 1-1 1h-62c-.6 0-1-.4-1-1 0-.5.4-1 1-1h62c.5 0 1 .5 1 1z" />
        <path d="m7.9 36.9c0 3.5 1.2 7.2 3.3 10.3.3.5.2 1.1-.3 1.4-.4.3-1.1.2-1.4-.3-2.3-3.4-3.6-7.3-3.6-11.3 0-.6.4-1 1-1 .5 0 1 .4 1 .9z" />
        <path d="m2.2 21.9c0-3.3 2.7-6 6-6s6 2.7 6 6-2.7 6-6 6-6-2.6-6-6z" />
      </g>
    </svg>
  );
};

export default FallDetected2;
