import React from 'react';
import styled, { keyframes } from 'styled-components';

const generateGradientColors = () => {
  const baseColor = '#78B69B';
  const lighterColor = '#ABE188';
  const darkerColor = '#5E916B'; // Added a darker color for variance
  return `linear-gradient(to right, ${baseColor}, ${lighterColor}, ${darkerColor}, ${lighterColor}, ${baseColor})`;
};

const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
`;

const AnimatedGradientText = styled.h1`
  font-size: ${(props) => props.size || '2em'};
  animation: ${gradientAnimation} 5s linear infinite;
  background: ${(props) => generateGradientColors()};
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin: 0;
`;

const TextGradientComponent = ({ text, size }) => {
  return <AnimatedGradientText size={size}>{text}</AnimatedGradientText>;
};

export default TextGradientComponent;
