import React from 'react';

const LogoVector = ({ maxWidth, maxHeight }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 440 406"
      style={{ enableBackground: 'new 0 0 440 406',
      width: '100%',
      height: 'auto',
      maxWidth: maxWidth || '100%',
      maxHeight: maxHeight || '100%',
    
    }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{opacity:0.75;}
        .st1{fill:#FFFFFF;stroke:#78B69B;stroke-width:1.5;stroke-miterlimit:10;}`}
      </style>
      <g className="st0">
        <path
          className="st1"
          d="M239.51,249.63c0.25-7.34-0.21-14.71-1.43-21.96c-1.09-6.43-2.78-12.75-5.06-18.86
          c-2.04-5.46-4.53-10.74-7.45-15.78c-4.27-7.4-9.34-14.36-15.18-20.6c-2.91-3.11-6.02-6.05-9.3-8.77
          c-2.99-2.47-6.59-6.12-10.54-6.62c-1.96-0.25-3.37,1.04-4.58,2.4c-1.46,1.63-2.6,3.51-3.81,5.32c-2.35,3.52-4.56,7.13-6.64,10.83
          c-3.07,5.48-5.48,11.48-7.34,17.48c-1.54,4.85-2.77,9.8-3.65,14.82c-0.39,2.25-0.74,4.51-0.92,6.79c0,0.03-0.01,0.05-0.01,0.08
          c-2.06,64.17,56.42,72.42,56.42,72.42s-30.55-56.28-20.1-73.41C199.92,213.76,237.96,295.73,239.51,249.63z"
        />
        <path
          className="st1"
          d="M246.9,256.45c-0.19-5.6,0.16-11.23,1.09-16.75c0.83-4.9,2.12-9.73,3.86-14.39c1.55-4.16,3.46-8.19,5.68-12.04
          c3.26-5.65,7.12-10.95,11.58-15.72c2.22-2.38,4.59-4.62,7.1-6.69c2.28-1.89,5.03-4.67,8.04-5.05c1.5-0.19,2.57,0.79,3.5,1.83
          c1.11,1.24,1.98,2.68,2.9,4.06c1.8,2.69,3.48,5.44,5.07,8.26c2.34,4.18,4.18,8.76,5.6,13.33c1.18,3.7,2.11,7.48,2.78,11.3
          c0.3,1.71,0.57,3.44,0.7,5.18c0,0.02,0,0.04,0,0.06c1.57,48.95-43.04,55.25-43.04,55.25s23.31-42.94,15.33-56.01
          C277.11,229.08,248.09,291.62,246.9,256.45z"
        />
        <path
          className="st1"
          d="M218.84,354.87c0.07,0.05,0.13,0.09,0.2,0.14c-38.4-13.63-75.93-31.07-107.72-56.56
          c-17.72-14.22-33.51-31.01-45.94-50.06c-13.46-20.63-22.92-44.07-25.91-68.61c-2.33-19.13-0.88-38.81,4.86-57.24
          c6.89-22.13,20.49-41.05,39.95-53.83c2.23-1.46,4.52-2.84,6.86-4.12c21.65-11.83,47.27-16.09,71.61-12.19
          c20.46,3.28,40.66,12.17,55.44,26.92c3.3,3.29,5.87,7.11,10.65,8.38c6.3,1.68,11.5-2.15,16.36-5.62
          c20.33-14.54,43.11-23.7,68.57-22.28c0.25,0.01,0.5,0.03,0.76,0.05c2.72,0.17,5.43,0.46,8.13,0.87c5.11,0.78,10.16,1.98,15.07,3.63
          c25.68,8.61,47.05,28.94,57.34,53.93c12.91,31.32,4.71,63.87-7.84,93.91c-0.65,1.55-1.3,3.09-1.97,4.63
          c-20.49,47.31-58.93,96.79-108.64,115.42c-31.37,11.76-59.19-0.72-84.24-20.31c-0.97-0.76-1.93-1.52-2.88-2.29
          c-16.16-13.01-30.44-28.42-42.21-45.5c-12.96-18.81-22.79-39.71-28.9-61.72c0,0,77.08,139.03,160.28,102.55
          c0,0,65.5-28.25,84.14-104.88c0,0,22.64-86.87-60.56-93.1c-7.88-0.59-16.51,1.05-24.15,2.67c-7.95,1.68-15.76,4.12-23.15,7.51
          c-5.63,2.59-10.75,5.62-15.54,9.52c-1.63,1.32-3.53,2.5-5.63,2.54c-4.24,0.08-8.54-4.49-11.43-7.13
          c-3.53-3.22-6.82-6.69-10.49-9.75c-6.08-5.08-13.33-8.9-20.55-12.07c-15.17-6.67-32.09-9.61-48.57-7.42
          c-23.98,3.19-46.63,17.8-57.61,39.35c-7.33,14.4-9.32,31.16-7.39,47.21c1.93,16.05,7.59,31.46,14.85,45.9
          C119.96,279.76,168.81,320.05,218.84,354.87z"
        />
        <circle className="st1" cx="212.6" cy="144.58" r="18.91" />
        <circle className="st1" cx="265.1" cy="175.72" r="14.9" />
      </g>
      <g className="st0"></g>
      <g className="st0"></g>
    </svg>
  );
};

export default LogoVector;
