import React from 'react';
const BedExit = ({ width, height, color, strokeWidth, strokeColor }) => {
    return(
        <svg 
        id="Layer_1" 
        enable-background="new 0 0 349.975 190.335" 
        width={width}
        height={height}
        viewBox="0 0 355 200" 
        xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color} stroke={strokeColor} strokeWidth={strokeWidth} >
                <path d="m40.5 148.001v-52.817c0-6.629-5.373-12-12-12h-16.5c-6.627 0-12 5.371-12 12v78.316h40.5v-7h104.763c-2.304-6.497-5.35-12.676-9.144-18.5h-95.619z"/>
                <path d="m337.975 83.184h-16.5c-6.627 0-12 5.371-12 12v52.816h-140.447c2.545 5.64 4.778 11.805 6.523 18.5h133.924v7h40.5v-78.316c0-6.628-5.373-12-12-12z"/>
                <path d="m108.231 120.491c-.114-.063-.173-.1-.229-.137-1.513-.845-3.03-1.689-4.519-2.518-5.948-3.309-11.435-6.361-16.587-9.502h-29.83c-6.627 0-12 5.373-12 12v11.332c0 6.629 5.373 12 12 12h76.034c-2.089-2.831-4.352-5.577-6.811-8.225-9.335-10.053-17.972-14.901-18.058-14.95z"/>
                <path d="m146.36 114.457c6.599 6.888 14.294 16.616 20.608 29.21h125.94c6.627 0 12-5.371 12-12v-11.332c0-6.627-5.373-12-12-12h-152.847c2.155 1.956 4.262 3.996 6.299 6.122z"/>
                <path d="m124.206 76.242c-11.088-7.396-15.874-15.74-19.388-26.382 7.532.438 18.478 2.262 32.203 2.286h.068c12.257.001 26.832-1.537 43.711-6.849 4.215-1.324 6.559-5.814 5.233-10.03-1.325-4.214-5.815-6.558-10.03-5.233-15.263 4.789-28.026 6.106-38.982 6.112-14.212.027-25.199-2.313-35.404-2.381-3.303.004-6.596.272-9.896 1.204-1.146.324-2.158.89-3.009 1.616-.327.147-.64.306-.913.492-10.334 7.147-20.67 14.29-31.004 21.439-.255.173-.508.398-.76.648h-25.58c.936-1.776 1.92-3.64 2.908-5.504 2.276-4.292 4.575-8.584 6.319-11.749.6-1.088 1.129-2.033 1.562-2.79-3.679-3.968-5.916-8.977-6.406-14.382-1.386.178-2.226.615-2.805.907-1.36.79-1.667 1.231-2.016 1.554-.569.613-.73.877-.937 1.156-.655.951-1.131 1.788-1.861 3.058-4.82 8.471-17.05 32.001-17.083 32.06-1.294 2.488-1.196 5.443.26 7.842 1.456 2.4 4.033 3.85 6.839 3.85h35.968c8.971 12.444 18.024 20.457 28.407 27.422 9.189-3.446 36.984-14.678 42.596-26.346z"/>
                <path d="m144.916 115.841c-3.313-3.459-6.52-6.403-9.459-8.883-18.489-6.638-24.794-5.916-47.286-.192 6.341 3.856 13.207 7.595 20.845 11.865.051.028.096.062.147.09l-.001.001c.367.193 9.188 5.23 18.595 15.36 9.435 10.165 19.407 25.167 23.178 45.977 1.093 6.04 6.357 10.276 12.286 10.276.738.001 1.486-.064 2.239-.201 6.793-1.228 11.304-7.732 10.076-14.525-4.943-27.358-18.479-47.095-30.62-59.768zm-35.715 2.901c.001 0 .001 0 0 0z"/>
                <path d="m164.648 52.147-38.777 24.899c1.858 4.774 6.313 14.978 12.643 21.593l32.09-20.605 30.49 24.393c5.391 4.313 13.256 3.439 17.568-1.951 4.313-5.391 3.439-13.258-1.951-17.57l-37.5-30c-4.187-3.349-10.052-3.655-14.563-.759z"/><ellipse cx="59.235" cy="22.5" rx="22.499" ry="22.5" transform="matrix(.679 -.734 .734 .679 2.482 50.68)"/>
            </g>
            </svg>
    );
};

export default BedExit;
