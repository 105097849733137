let socket;

export const initializeWebSocket = async (uniqueIdentifier, value) => {
    return new Promise((resolve, reject) => {
        const connectWebSocket = () => {
        socket = new WebSocket(`wss://w7yd421is8.execute-api.us-east-1.amazonaws.com/prod?businessPin=${value}`);

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            const deviceData = data.message;
            console.log('Response received:', deviceData);

            if (deviceData && Array.isArray(deviceData)) {
                await deviceData.forEach((device) => {
                    if (!device.hasOwnProperty('timestamp')) {
                        // Set a default timestamp if it doesn't exist
                        device.timestamp = null;
                    }
                });
                try {
                    await localStorage.setItem(`deviceData${uniqueIdentifier}`, JSON.stringify(deviceData));
                    resolve(true); // Resolve the Promise after the storage is successful
                } catch (storageError) {
                    reject(storageError); // Reject the Promise if there's an error during storage
                }
            } else if (deviceData && typeof deviceData === 'object' && deviceData.hasOwnProperty("deviceId")) {
                const currentDeviceData = JSON.parse(localStorage.getItem(`deviceData${uniqueIdentifier}`)) || [];
                const deviceIndex = currentDeviceData.findIndex(device => device.deviceId === deviceData.deviceId);
                try {
                    if (deviceIndex !== -1) {
                        currentDeviceData[deviceIndex] = { ...currentDeviceData[deviceIndex], ...deviceData };
                        localStorage.setItem(`deviceData${uniqueIdentifier}`, JSON.stringify(currentDeviceData));
                        console.log("Device data updated and stored successfully.");
                    } else {
                        console.error("Device not found for update.");
                    }
                } catch (error) {
                    console.error("Error updating and storing device data:", error);
                }
            } else {
                reject(false)
            }
        };
        socket.onopen = () => {
            console.log('WebSocket connection established');
            // Additional initialization logic if needed
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            // Additional cleanup logic if needed
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            console.error('WebSocket error message:', error.message);
            reject(false)
            // Handle WebSocket errors if needed
        };
    };
    connectWebSocket();
    });
};

export const closeWebSocket = () => {
    if (socket) {
        socket.close();
    }
};

// export const sendWebSocketMessage = (message) => {
//   if (socket && socket.readyState === WebSocket.OPEN) {
//     socket.send(JSON.stringify(message));
//   } else {
//     console.error('WebSocket not open. Unable to send message:', message);
//   }
// };
